import React, {useState} from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import {useTranslation, Link} from 'gatsby-plugin-react-i18next';
import useDayJS from '../libraries/useDayJS'
import DiscussionEmbed from "disqus-react";
import * as i18nUtil from 'i18n-util'
import CardContent from "@mui/material/CardContent";


function PostDetail({post}) {

  const {t, i18n: {language}} = useTranslation();
  const [isCommentVisible, setIsCommentVisible] = useState(false)

  return (
    <Card>
      <CardContent sx={{px: {xs: 1, md: 2}}}>
        <Typography variant="title">{post.title}</Typography>
        <Typography variant="info" gutterBottom title={useDayJS(language, {date_time: post.publish_date_time, format: 'LLLL'})}>
          {useDayJS(language, {date_time: post.publish_date_time, fromNow: true})}
        </Typography>
        <Stack direction="row" spacing="8px">
          {post.tag_ids.map(tag => <Chip
            key={tag.id_odoo}
            sx={{fontSize: 11}}
            label={tag.title}
            color="info"
            size="small"
            variant="outlined"
            clickable
            component={Link}
            to={`/tag/${tag.id_odoo}/?_=${tag.slug}`}
          />)}
        </Stack>
        <Box dangerouslySetInnerHTML={{__html: post.content}}/>
        {isCommentVisible ? (
            <DiscussionEmbed
              shortname='devsou-com'
              config={
                {
                  url: `https://devsou.com/post/${post.id_odoo}/?_=${post.slug}`,
                  identifier: post.id_odoo,
                  title: post.title,
                  language: i18nUtil.normalize(language),
                }
              }
            />
        ) : (
          <Button size="large" fullWidth variant="contained"
                  onClick={() => setIsCommentVisible(true)}>{t("Load Disqus Comments")}</Button>
        )}
      </CardContent>
    </Card>
  )
}

export default PostDetail
