import {useEffect, useState} from 'react';
import useIsFirstRender from "./useIsFirstRender";
import dayJS from '../configs/day'


/**
 * @param {string} language
 * @param {string} [date_time]
 * @param {string} [format]
 * @param {boolean} [fromNow]
 * @returns {string|dayjs.Dayjs|*}
 */
export default function useDayJS(language, {date_time, format, fromNow} = {}) {

  const isFirstRender = useIsFirstRender();
  const [result, setResult] = useState(() => dayJS(language, {date_time, format, fromNow}));

  useEffect(() => {
    if (isFirstRender) {
      return
    }
    setResult(dayJS(language, {date_time, format, fromNow}))
  }, [isFirstRender, language, date_time, format, fromNow]);

  return result;
}
