import 'dayjs/locale/pt-br'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import dayjs from 'dayjs'


dayjs.extend(utc)
dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)

// let globalLocale = null

/**
 * @param {string} language
 * @param {string} [date_time]
 * @param {string} [format]
 * @param {boolean} [fromNow]
 * @returns {string|dayjs.Dayjs|*}
 */
export default function dayJS(language, {date_time, format, fromNow} = {}) {
  //
  // This is supposed to work, but it doesn't.
  //
  // if (globalLocale !== language) {
  //   globalLocale = language
  //   dayjs.locale(globalLocale)
  // }
  const result = dayjs.utc(date_time).local().locale(language)
  if (format) {
    return result.format(format)
  }
  if (fromNow) {
    return result.fromNow()
  }
  return result
}
