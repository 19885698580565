import * as React from "react"
import PostDetail from '../components/PostDetail'
import Layout from '../components/Layout'
import {graphql} from "gatsby";
import {Link, useTranslation} from 'gatsby-plugin-react-i18next';


export const query = graphql`
  query ($language: String!, $id_odoo: Int!) {

    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    archiveCaveSoftPost(id_odoo: {eq: $id_odoo}, lang: {eq: $language}) {
      id_odoo
      write_date
      title
      content
      slug
      publish_date_time
      image_sources
      video_sources
      short_content
      tag_ids {
        id_odoo
        title
        slug
      }
    }
  }
`

/**
 * @returns {JSX.Element}
 * @constructor
 */
function Post({data: {archiveCaveSoftPost: post}}) {

  const {t} = useTranslation();

  return (
    <Layout breadcrumbs={[<Link key={1} to={`/`}>{t("Posts")}</Link>]} showGoBack maxWidth="md">
      <PostDetail post={post}/>
    </Layout>
  )
}

export default Post
